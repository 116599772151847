import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import { styled } from '@mui/material/styles'
import Switch, { SwitchProps } from '@mui/material/Switch'
import React from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'

import { useFieldName, useInputFormContext } from './input-form-hooks'

export interface InputFieldSwitchProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Pick<SwitchProps, 'required' | 'disabled'> {
  name: TName
  label: string
  defaultValue?: FieldPathValue<TFieldValues, TName>
}

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
}))

export default function InputFieldSwitch<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ name, label, required, defaultValue, disabled }: InputFieldSwitchProps<TFieldValues, TName>) {
  const inputForm = useInputFormContext()
  const fieldName = useFieldName<TName>(name)

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: { required },
    defaultValue,
  })

  return (
    <FormControl
      error={Boolean(fieldState.error)}
      fullWidth>
      <FormControlLabel
        control={
          <Switch
            ref={field.ref}
            disabled={disabled || inputForm.disabled}
            name={field.name}
            onBlur={inputForm.onBlur(fieldName, field.onBlur)}
            onChange={field.onChange}
            value={field.value || false}
          />
        }
        label={label}
      />
      <StyledFormHelperText>{fieldState.error && fieldState.error.message}</StyledFormHelperText>
    </FormControl>
  )
}
