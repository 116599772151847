import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { createContext, PropsWithChildren, ReactNode, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { useFieldName, useInputFormContext } from './input-form-hooks'
import InputGroup, { InputGroupProps } from './input-group'

type CollectionItem<T> = T & Record<'id', string>

export interface InputCollectionProps<T> {
  name: string
  gridSize?: number

  onCreate?: (value: T) => void
  onUpdate?: InputGroupProps<T>['onUpdate']
  inMemory?: boolean
  disableCreate?: boolean
  // When `true` it will add the `order` field in the patch request when creating a new record
  addOrderFieldToCreate?: boolean
  // Separate children for creating a new group, for example, using the <InputGroupAddButton /> as createChildren
  // it will render a button where the user can click on instead of having all fields in an empty state.
  createChildren?: ReactNode
  // Callback that can be used to filter the groups created by react-hook-form, potentially to split
  // an array of items into two separate collections.
  filterGroupFields?: (fields: Array<CollectionItem<T>>) => Array<CollectionItem<T>>
  className?: string
  // Also show "no items" message when not in disabled state
  alwaysShowMessageWhenEmpty?: boolean
}

export const inputCollectionClasses = generateUtilityClasses('InputCollection', [
  'root',
  'group',
  'createContainer',
  'noItems',
])

const Root = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'gridSize',
})<{
  gridSize: number
}>(({ gridSize }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${gridSize}, 1fr)`,
}))

export const InputCollectionContext = createContext({
  expandedAccordionName: '',
  changeExpandedAccordionName: (accordionName: string, isExpanding: boolean) => {
    // Do nothing
  },
})

export default function InputCollection<T>({
  name,
  gridSize = 1,
  children,

  onCreate,
  onUpdate,
  inMemory = false,
  disableCreate = false,
  addOrderFieldToCreate = false,
  createChildren,
  filterGroupFields = (fields) => fields,
  className,
  alwaysShowMessageWhenEmpty,
}: PropsWithChildren<InputCollectionProps<T>>) {
  const [expandedAccordionName, setExpandedAccordionName] = useState('')

  const inputForm = useInputFormContext()
  const formContext = useFormContext()
  const fieldName = useFieldName(name)
  const { fields, update } = useFieldArray({ name: fieldName })

  // For in memory input collections, set the value directly
  const handleCreate = (value: T) => {
    if (inMemory) {
      update(fields.length, value)
      setTimeout(() => {
        formContext.setFocus(`${fieldName}.${fields.length + 1}`)
      })
    }

    if (onCreate) {
      onCreate(value)
    }
  }

  const handleExpandedAccordionNameChange = (accordionName: string, isExpanding: boolean) => {
    setExpandedAccordionName((prevState) => {
      if (accordionName === prevState && !isExpanding) {
        return ''
      }

      return accordionName
    })
  }

  const filteredGroupFields = filterGroupFields(fields as Array<CollectionItem<T>>)

  return (
    <InputCollectionContext.Provider
      value={{
        expandedAccordionName,
        changeExpandedAccordionName: handleExpandedAccordionNameChange,
      }}>
      <Root
        className={clsx(inputCollectionClasses.root, className)}
        gap={2}
        gridSize={gridSize}>
        {filteredGroupFields.map((field, fieldIndex) => (
          <Stack
            key={field.id}
            className={inputCollectionClasses.group}>
            <InputGroup
              key={field.id}
              // Prevents, if we render the collection with the same name twice
              // that it will cause the inputs to render the same value.
              // When the order field exists, we use that as its more stable than the
              // fieldIndex in the above-described case.
              name={`${fieldName}.${'order' in field ? field.order : fieldIndex}`}
              onUpdate={onUpdate}>
              {children}
            </InputGroup>
          </Stack>
        ))}

        {(inputForm.disabled || alwaysShowMessageWhenEmpty) && filteredGroupFields.length === 0 && (
          <div>No items have been added yet.</div>
        )}

        {!disableCreate && (!inputForm.disabled || inputForm.saving) && (
          <Stack
            className={clsx(inputCollectionClasses.createContainer, inputCollectionClasses.group)}>
            <InputGroup
              addOrderFieldToCreate={addOrderFieldToCreate}
              name={`${fieldName}.${fields.length}`}
              onCreate={handleCreate}
              isCreate>
              {createChildren || children}
            </InputGroup>
          </Stack>
        )}
      </Root>
    </InputCollectionContext.Provider>
  )
}
