import HelpIcon from '@mui/icons-material/Help'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import React from 'react'

import Markdown from '../../common/markdown'
import { getFromConsistencyGuide } from '../../features/consistency-guide'
import NotificationModal, {
  NOTIFICATION_MODAL_TYPE_HELP,
} from '../../Modals/Notification/NotificationModal'
import { toastExplain } from '../../util/toastExplain'

export interface InputLabelConsistencyGuideProps {
  name: string
  label: string
}

const StyledIconButton = styled(IconButton)({
  pointerEvents: 'all',
})

// TODO:: Refactor and remove toast
export default function InputLabelConsistencyGuideInfo({
  name,
  label,
}: InputLabelConsistencyGuideProps) {
  const markdown = getFromConsistencyGuide(name)

  if (!markdown) {
    return null
  }

  return (
    <StyledIconButton
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()

        toastExplain(
          <NotificationModal
            body={<Markdown source={markdown} />}
            header={label}
            modalType={NOTIFICATION_MODAL_TYPE_HELP}
          />
        )()
      }}
      size={'small'}>
      <HelpIcon />
    </StyledIconButton>
  )
}
