import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'
import { styled } from '@mui/material/styles'
import { ChangeEvent, useCallback } from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'

import { useFieldName, useInputFormContext } from './input-form-hooks'

export interface InputFieldCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  name: TName
  label?: string
  disabled?: boolean
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}))

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}))

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
}))

export default function InputFieldCheckbox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ name, label, disabled }: InputFieldCheckboxProps<TFieldValues, TName>) {
  const inputForm = useInputFormContext()
  const fieldName = useFieldName<TName>(name)

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
  })

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      field.onChange(event)
      inputForm.onBlur(fieldName, field.onBlur, true)(event)
    },
    [field, fieldName, inputForm]
  )

  return (
    <StyledFormControl>
      <StyledFormGroup>
        <FormControlLabel
          key={name}
          control={
            <Checkbox
              ref={field.ref}
              checked={!!field.value}
              disabled={inputForm.disabled || disabled}
              name={field.name}
              onChange={handleChange}
            />
          }
          label={label}
        />
      </StyledFormGroup>
      <StyledFormHelperText>{fieldState.error && fieldState.error.message}</StyledFormHelperText>
    </StyledFormControl>
  )
}
