import { Option } from '@gain/rpc/shared-model'
import InputLabel, { inputLabelClasses } from '@mui/material/InputLabel'
import { styled } from '@mui/material/styles'
import { enUS } from 'date-fns/locale/en-US'
import React from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'

import { InputFieldClearButton, InputFieldNumber, InputFieldSelect } from './index'

export interface InputFieldPartialDateProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  name: TName
  label?: string
}

const MONTHS: readonly Option<number>[] = Object.freeze(
  new Array(12).fill(null).map((_, i) => ({
    label: enUS.localize?.month(i as never),
    value: i + 1,
  }))
)

const StyledRoot = styled('div')(({ theme }) => ({
  [`& .${inputLabelClasses.root}`]: {
    transform: 'none',
    ...theme.typography.caption,
    // Creep up a little bit (similar to text fields)
    marginTop: theme.spacing(-0.5),
    marginBottom: theme.spacing(0.5),
  },
}))

const StyledGroupFieldsContainer = styled('div')(({ theme }) => ({
  minWidth: 250,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 32px',
  gap: theme.spacing(1),
}))

export default function InputFieldPartialDate<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ name, label }: InputFieldPartialDateProps<TFieldValues, TName>) {
  return (
    <StyledRoot>
      <InputLabel size={'small'}>{label}</InputLabel>
      <StyledGroupFieldsContainer>
        <InputFieldNumber
          name={`${name}.year`}
          placeholder={'year'}
          size={'small'}
        />
        <InputFieldSelect
          name={`${name}.month`}
          options={MONTHS}
          placeholder={'month'}
          size={'small'}
        />

        <InputFieldClearButton name={name} />
      </StyledGroupFieldsContainer>
    </StyledRoot>
  )
}
