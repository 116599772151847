import { UserListItem } from '@gain/rpc/cms-model'
import { AssetProfileType } from '@gain/rpc/shared-model'
import { listFilter } from '@gain/rpc/utils'
import Stack from '@mui/material/Stack'
import React, { ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import * as yup from 'yup'

import {
  InputFieldAutoComplete,
  InputFieldDate,
  InputFieldRegion,
  InputFieldSwitch,
  InputFieldText,
  InputFormProvider,
  useInputFormAPI,
} from '../../../common/input-fields'

// Only these profile types can be created through this form
const SUPPORTED_PROFILE_TYPES: ReadonlyArray<AssetProfileType> = [
  AssetProfileType.Full,
  AssetProfileType.Limited,
]

interface CuratedAssetFormProps {
  profileType: AssetProfileType
  onCreate?: (assetId: number) => void
}

// Expose submit handler via forwarded ref to allow the containing component
// to handle form submission
export interface CuratedAssetFormRef {
  submit: () => Promise<void>
}

/**
 * Allows the user to create a curated asset profile. Form submission is
 * delegated to containing component via ref.
 */
function CuratedAssetForm(
  { profileType, onCreate }: CuratedAssetFormProps,
  ref: ForwardedRef<CuratedAssetFormRef>
) {
  const inputFormAPI = useInputFormAPI({
    defaultValues: {
      projects: [],
      assignees: [],
      priority: false,
    },
    validationSchema: {
      name: yup.string().trim().required(),
      headquarters: yup.string().required(),
      dueDate: yup.date(),
      priority: yup.boolean().required(),
      projects: yup.array().required(), // Check type
      assignees: yup.array().required(), // Check type
    },
    createMethod: 'data.createCuratedAsset',
  })

  const disabled = !SUPPORTED_PROFILE_TYPES.includes(profileType)

  const handleSubmit = inputFormAPI.form.handleSubmit(async (values) => {
    await inputFormAPI.create(
      {
        profileType,
        name: values.name,
        headquarters: values.headquarters,
        priority: values.priority,
        dueDate: values.dueDate?.toISOString() || null,
        assigneeIds: values.assignees.map(({ id }) => id),
        projectIds: values.projects.map(({ id }) => id),
      },
      (assetId) => {
        // Let container component know about successful creation
        onCreate?.(assetId)
      }
    )
  })

  // Forward submit handler to the ref
  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
  }))

  return (
    <InputFormProvider form={inputFormAPI.form}>
      <Stack
        gap={2}
        mt={0.5}>
        <InputFieldText
          disabled={disabled}
          label={'Company name'}
          name={'name'}
          required
        />
        <Stack
          alignItems={'center'}
          direction={'row'}
          gap={2}>
          <InputFieldDate
            disabled={disabled}
            label={'Due date'}
            name={'dueDate'}
          />

          <InputFieldRegion
            disabled={disabled}
            label={'Region'}
            name={'headquarters'}
            required
          />

          <InputFieldSwitch
            disabled={disabled}
            label={'Has priority?'}
            name={'priority'}
          />
        </Stack>
        <InputFieldAutoComplete
          disabled={disabled}
          label={'Projects'}
          labelProp={'name'}
          method={'cms.listProjects'}
          name={'projects'}
          valueProp={'id'}
          multiple
        />
        <InputFieldAutoComplete
          defaultFilter={[
            listFilter<UserListItem>('status', '=', 'active'),
            listFilter<UserListItem>('customerId', '=', 1),
          ]}
          disabled={disabled}
          getOptionLabel={(user) => (user ? `${user.firstName} ${user.lastName}` : '')}
          label={'Assign'}
          labelProp={'firstName'}
          method={'customer.listUsers'}
          name={'assignees'}
          valueProp={'id'}
          enableCheckboxes
          multiple
        />
      </Stack>
    </InputFormProvider>
  )
}

export default forwardRef(CuratedAssetForm)
