import { getLabelFromOption, Option } from '@gain/rpc/shared-model'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel, { inputLabelClasses } from '@mui/material/InputLabel'
import Rating from '@mui/material/Rating'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import React, { useCallback, useState } from 'react'
import { FieldPath, FieldPathValue, FieldValues, useController } from 'react-hook-form'

import { useFieldName, useInputFormContext } from './input-form-hooks'

export interface InputFieldRatingProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  name: TName
  label: string
  options: Option<number>[]
  ratingTooltip?: string
  disabled?: boolean
  required?: boolean
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center',

  [`& .${inputLabelClasses.root}`]: {
    position: 'static',
    transform: 'none',
    ...theme.typography.subtitle1,
    margin: 0,
    padding: 0,
    flex: 1,
  },
}))

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
}))

const StyledSpan = styled('span')({
  display: 'flex', // Prevent additional spacing
})

export default function InputFieldRating<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  options,
  ratingTooltip,
  disabled,
  required,
}: InputFieldRatingProps<TFieldValues, TName>) {
  const inputForm = useInputFormContext()
  const fieldName = useFieldName<TName>(name)
  const [hover, setHover] = useState(-1)

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
  })

  const handleChange = useCallback(
    (_, newValue: number | null) => {
      field.onChange(newValue as FieldPathValue<TFieldValues, TName>)
      inputForm.onBlur(fieldName, field.onBlur, true)()
    },
    [field, fieldName, inputForm]
  )

  return (
    <StyledFormControl fullWidth>
      <InputLabel
        required={required}
        shrink={false}>
        {label}
      </InputLabel>

      <Stack
        alignItems={'center'}
        flex={1}
        flexDirection={'row'}
        gap={1}>
        <Tooltip
          title={ratingTooltip}
          arrow
          disableInteractive>
          <StyledSpan>
            <Rating
              ref={field.ref}
              disabled={inputForm.disabled || disabled}
              name={field.name}
              onChange={handleChange}
              onChangeActive={(_, hoverValue) => {
                setHover(hoverValue)
              }}
              precision={options.some((option) => option.value === 0.5) ? 0.5 : 1}
              size={'large'}
              value={field.value || 0}
            />
          </StyledSpan>
        </Tooltip>
        <span>{getLabelFromOption(options, hover !== -1 ? hover : field.value)}</span>
      </Stack>

      <StyledFormHelperText>{fieldState.error && fieldState.error.message}</StyledFormHelperText>
    </StyledFormControl>
  )
}
