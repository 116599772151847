import Tooltip from '@mui/material/Tooltip'
import { ReactElement, ReactNode } from 'react'

import DeleteButton from '../../Components/Buttons/DeleteButton'
import InputError from '../../Components/FormElements/Error/InputError'
import Icon, {
  DIRECTION_DOWN,
  DIRECTION_RIGHT,
  ICON_CHEVRON,
  ICON_COPY,
} from '../../Components/Icons/Icon'
import IconInCircle from '../../Components/Icons/icon-in-circle'
import {
  FlexContainer,
  FLEXCONTAINER_REPEATER,
} from '../../Components/Tabular/FlexTable/FlexContainer'
import FlexItem, { FLEXITEM_ROUNDBUTTON } from '../../Components/Tabular/FlexTable/FlexItem'
import ToggleBody from '../../Components/Toggler/ToggleBody'
import ToggleHead from '../../Components/Toggler/ToggleHead'
import Toggler from '../../Components/Toggler/Toggler'
import { COLOR_TINT } from '../../util/colors'
import CopyArrayItemComposition from '../ItemActions/CopyArrayItemComposition'
import DeleteArrayItemComposition from '../ItemActions/delete-array-item-composition'
import { ErrorContainer } from '../UpdateForm/ErrorContainer'

export interface ToggleRepeaterCompositionProps {
  name: string
  isOpen: boolean
  setOpen: () => void
  opener: ReactElement
  disableDelete?: boolean
  disableDeleteReason?: string
  copy?: boolean
  children: ReactNode
}

export function ToggleRepeaterComposition({
  name,
  isOpen,
  setOpen,
  opener,
  children,
  copy = false,
  disableDeleteReason,
  disableDelete = false,
}: ToggleRepeaterCompositionProps) {
  return (
    <Toggler isOpen={isOpen}>
      <ToggleHead isOpen={isOpen}>
        <FlexContainer
          style={{ padding: 12 }}
          styleName={FLEXCONTAINER_REPEATER}>
          <FlexItem onClick={setOpen}>
            <FlexContainer>
              <ErrorContainer path={name}>
                {(onClick, hasErrors) => (
                  <InputError
                    hasErrors={hasErrors}
                    onClick={onClick}
                    style={{
                      marginTop: 8,
                      marginLeft: -36,
                    }}
                  />
                )}
              </ErrorContainer>
              <FlexItem styleName={FLEXITEM_ROUNDBUTTON}>
                <Icon
                  direction={isOpen ? DIRECTION_DOWN : DIRECTION_RIGHT}
                  iconColor={COLOR_TINT}
                  src={ICON_CHEVRON}
                  style={{ fontSize: 8 }}
                />
              </FlexItem>
              <FlexItem>{opener}</FlexItem>
            </FlexContainer>
          </FlexItem>
          {copy && (
            <FlexItem styleName={FLEXITEM_ROUNDBUTTON}>
              <CopyArrayItemComposition path={name}>
                {(copyItem) => (
                  <IconInCircle
                    colorStyle={'tint-diap'}
                    icon={ICON_COPY}
                    onClick={copyItem}
                  />
                )}
              </CopyArrayItemComposition>
            </FlexItem>
          )}
          <FlexItem styleName={FLEXITEM_ROUNDBUTTON}>
            {disableDelete ? (
              <Tooltip title={disableDeleteReason || ''}>
                <DeleteButton />
              </Tooltip>
            ) : (
              <DeleteArrayItemComposition path={name}>
                {(deleteItem) => <DeleteButton onClick={deleteItem} />}
              </DeleteArrayItemComposition>
            )}
          </FlexItem>
        </FlexContainer>
      </ToggleHead>
      <ToggleBody isOpen={isOpen}>{children}</ToggleBody>
    </Toggler>
  )
}
