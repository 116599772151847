export enum DealRouteTabs {
  General = 'general',
  BuyerAndSellers = 'buyers_sellers',
  Advisors = 'advisors',
  Sources = 'sources',
}

const DEAL_PAGE_PATH = '/deals/:id'
export const ROUTE_DEAL_TABS_PATH = `${DEAL_PAGE_PATH}/:tab?`
export const DEAL_GENERAL_PATH = `${DEAL_PAGE_PATH}/${DealRouteTabs.General}`
export const DEAL_BUYER_AND_SELLER_PATH = `${DEAL_PAGE_PATH}/${DealRouteTabs.BuyerAndSellers}`
export const DEAL_ADVISORS_PATH = `${DEAL_PAGE_PATH}/${DealRouteTabs.Advisors}`
export const DEAL_SOURCES_PATH = `${DEAL_PAGE_PATH}/${DealRouteTabs.Sources}`

export const DEAL_PREVIEW_PATH = '/app/deal/:id/:title'
