import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import MethodContext from '../../../Context/method.context'
import { partialAdd } from '../../../util/partial'
import TextArea from '../../../Components/FormElements/Input/Textarea'
import CharacterCount from '../../../common/input-fields/input-field-text-character-count'
import { isEnter } from '../../../util/isEnter'
import Stack from '@mui/material/Stack'

const AddInputTextArea = ({ path, length, rows, maxLength, title }) => {
  const [value, setValue] = useState('')

  const updateValue = (input, update) => {
    if (isEmpty(value)) {
      return
    }

    update(partialAdd(`${path}[${length}]`, { text: value, order: length }))

    // eslint-disable-next-line no-param-reassign
    input.value = ''
    input.focus()
    setValue(input.value)
  }

  return (
    <MethodContext.Consumer>
      {({ update }) => (
        <div style={{ position: 'relative' }}>
          <TextArea
            rows={rows}
            placeholder={title}
            input={{
              onBlur: ({ target }) => updateValue(target, update),
              onChange: ({ target }) => setValue(target.value),
              onKeyUp: ({ key, target }) => {
                if (isEnter(key)) {
                  updateValue(target, update)
                }
              },
            }}
          />
          {maxLength && (
            <Stack justifyContent={'end'}>
              <CharacterCount
                recommendedLength={maxLength}
                text={value}
              />
            </Stack>
          )}
        </div>
      )}
    </MethodContext.Consumer>
  )
}

AddInputTextArea.propTypes = {
  path: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
}

AddInputTextArea.defaultProps = {
  rows: 5,
  maxLength: undefined,
}

export default AddInputTextArea
