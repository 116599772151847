import LinkBehavior from '@gain/shared/components/link-behavior'
import { alertClasses } from '@mui/material/Alert'
import { avatarClasses } from '@mui/material/Avatar'
import { createTheme } from '@mui/material/styles'
import createPalette from '@mui/material/styles/createPalette'

const cmsTheme = createTheme({
  palette: createPalette({
    warning: {
      main: '#EDBD2E',
    },
    // Properties below can be ignored but are required because of
    // the theme module augmentation used for the app
    chart: [],
    cards: {
      owner: '',
      asset: '',
    },
    shadow: {
      level1a: '',
      level1b: '',
      level1c: '',
      level1d: '',
    },
  }),
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: 'Mulish, Arial, sans-serif',
    fontSize: 12,
    h1: {
      fontSize: 54,
      fontWeight: 800,
      lineHeight: '68px',
    },
    h2: {
      fontSize: 24,
      fontWeight: 800,
      lineHeight: '32px',
    },
    h3: {
      fontSize: 20,
      fontWeight: 800,
      lineHeight: '28px',
    },
    h4: {
      fontSize: 18,
      fontWeight: 800,
      lineHeight: '24px',
    },
    h5: {
      fontSize: 16,
      fontWeight: 800,
      lineHeight: '28px',
      letterSpacing: '0.02em',
    },
    h6: {
      fontSize: 14,
      fontWeight: 800,
      lineHeight: '18px',
    },
    body1: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '22px',
    },
    subtitle1: {
      // Caption 1
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: '24px',
    },
    subtitle2: {
      // Caption 2
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: '18px',
    },
    caption: {
      // Caption 3
      fontSize: 12,
      lineHeight: '14px',
    },
    button: {
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: '24px',
    },
    overline: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '18px',
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
        filled: ({ ownerState, theme }) => ({
          [`& .${alertClasses.action} button`]: {
            backgroundColor: 'white',
            color: theme.palette[ownerState.severity || 'success'].main,
          },
        }),
        action: {
          alignItems: 'center',
          padding: 0,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          html: {
            WebkitFontSmoothing: 'auto',
          },
          body: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          },
          'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
          },
          'input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        // Even though the component is overridable, the interface says otherwise
        component: LinkBehavior,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          fontSize: theme.typography.body2.fontSize,
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          [`&.${avatarClasses.rounded}`]: {
            borderRadius: 4,
          },
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {},
    },
    MuiAccordion: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
          },
        },
      },
    },
  },
})

cmsTheme.typography.h1[cmsTheme.breakpoints.down('md')] = {
  fontSize: 32,
  lineHeight: '40px',
}

cmsTheme.typography.h2[cmsTheme.breakpoints.down('md')] = {
  fontSize: 26,
  lineHeight: '32px',
}

export default cmsTheme
