import React from 'react'

import { BUTTON_STYLENAME_PILL } from '../../Components/Buttons/Button'
import { FormButton } from '../../Components/Buttons/FormButton/FormButton'
import InputGroupItem from '../../Components/FormElements/InputGroup/input-group-item'
import ModalBox from '../../Components/Modal/ModalBox'
import ModalBoxBody from '../../Components/Modal/ModalBoxBody'
import ModalBoxFoot from '../../Components/Modal/ModalBoxFoot'
import ModalBoxHead from '../../Components/Modal/ModalBoxHead'
import { toastCreate } from '../../util/toastCreate'
import { toastDismiss } from '../../util/toastDismiss'

const CONFIRM_DELETE_MODAL = 'TOAST_CONFIRM_DELETE_MODAL'

interface ConfirmDeleteModalProps {
  onConfirm: () => void
  confirmText?: string
  warningText?: string
}

/**
 * This modal is shown when a confirmation is required on delete. The
 * text for the confirmation can be passed as prop.
 */
function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
  const { onConfirm, confirmText, warningText } = props

  return (
    <ModalBox>
      <ModalBoxHead>Delete: are you sure?</ModalBoxHead>
      <ModalBoxBody>
        {confirmText && <InputGroupItem>{confirmText}</InputGroupItem>}
        {warningText && (
          <InputGroupItem>
            <strong>WARNING:</strong> {warningText}
          </InputGroupItem>
        )}
      </ModalBoxBody>
      <ModalBoxFoot>
        <FormButton
          onClick={onConfirm}
          styleName={BUTTON_STYLENAME_PILL}
          type={'button'}>
          Delete
        </FormButton>
      </ModalBoxFoot>
    </ModalBox>
  )
}

/**
 * Handles confirmation before deleting an item and adds an explicitly styled warning.
 */
export function confirmDeleteWithWarning(confirmText, warningText, deleteCallback: () => void) {
  toastCreate(
    <ConfirmDeleteModal
      confirmText={confirmText}
      onConfirm={() => {
        toastDismiss(CONFIRM_DELETE_MODAL)
        deleteCallback()
      }}
      warningText={warningText}
    />,
    CONFIRM_DELETE_MODAL
  )()
}
