import React from 'react'
import PropTypes from 'prop-types'
import MethodContext from '../../Context/method.context'
import { partialAdd } from '../../util/partial'
import PillButtonSecondary from '../../Components/Buttons/pill-button-secondary'
import { CLIPBOARD, readJsonFromClipboard } from '../../util/clipboard'
import { toastError } from '../../util/toastError'

const PasteArrayItemComposition = ({ path, order, deleteItems, children }) => (
  <MethodContext.Consumer>
    {({ update, disabled }) =>
      !disabled && (
        <PillButtonSecondary
          style={{
            marginLeft: '-8px',
          }}
          onMouseDown={() => {
            document.activeElement.blur()
          }}
          onClick={() => {
            readJsonFromClipboard(
              deleteItems,
              (json) => {
                update(
                  partialAdd(path, {
                    ...json,
                    order,
                  })
                )
              },
              (error) => toastError(`Paste from clipboard failed: ${error}`, CLIPBOARD)
            )
          }}>
          {children}
        </PillButtonSecondary>
      )
    }
  </MethodContext.Consumer>
)

PasteArrayItemComposition.propTypes = {
  path: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  deleteItems: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

PasteArrayItemComposition.defaultProps = {
  children: 'Add copied',
  deleteItems: [],
}

export default PasteArrayItemComposition
