import React from 'react'
import PropTypes from 'prop-types'
import MethodContext from '../../Context/method.context'
import { partialAdd } from '../../util/partial'
import PillButtonSecondary from '../../Components/Buttons/pill-button-secondary'
import InputGroupItem from '../../Components/FormElements/InputGroup/input-group-item'
import Divider from '../../Components/Divider/Divider'

// TOOD remove or move to utils
export const dataSize = (data) => {
  return data ? data.length : 0
}

const AddArrayItemComposition = ({ path, value, children }) => (
  <MethodContext.Consumer>
    {({ update, disabled }) =>
      disabled ? (
        value.order === 0 && <InputGroupItem>{children}</InputGroupItem>
      ) : (
        <>
          {value.order > 0 && <Divider />}
          <InputGroupItem>
            <PillButtonSecondary
              onMouseDown={() => {
                document.activeElement.blur()
              }}
              onClick={() => update(partialAdd(path, value))}>
              {children}
            </PillButtonSecondary>
          </InputGroupItem>
        </>
      )
    }
  </MethodContext.Consumer>
)

AddArrayItemComposition.defaultProps = {
  children: 'Add',
}

AddArrayItemComposition.propTypes = {
  path: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  children: PropTypes.string,
}

export default AddArrayItemComposition
