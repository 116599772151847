import { createSelector } from '@reduxjs/toolkit'
import get from 'lodash/get'
import React, { ReactNode, useMemo } from 'react'
import { FieldError, useFormContext } from 'react-hook-form'
import { FieldErrors } from 'react-hook-form/dist/types/errors'
import { useSelector } from 'react-redux'

import { ICON_EXCLAMATION_MARK } from '../../Components/Icons/Icon'
import IconInCircle from '../../Components/Icons/icon-in-circle'
import Text, { SIZE_CAPTION_2 } from '../../Components/Text/Text'
import NotificationModal from '../../Modals/Notification/NotificationModal'
import { COLORNAME_SUPPORT_3, COLORNAME_SUPPORT_4 } from '../../util/colors'
import { toastExplain } from '../../util/toastExplain'

/**
 * Select errors from redux state
 */
function errorsData(state: any) {
  return state.validateItem.data
}

interface ErrorType {
  path: string
  message: string
  type: 'warning' | 'error'
}

function useErrorSelector(path: string | string[]) {
  return useMemo(
    () =>
      createSelector([errorsData], (errors) =>
        errors && errors.length
          ? (errors.filter((item: any) =>
              Array.isArray(path)
                ? !!path.filter((filterPath) => item.path.startsWith(filterPath)).length
                : item.path === path
            ) as ErrorType[])
          : []
      ),
    [path]
  )
}

// Define error type we can use to extract messages from
type FieldErrorLike = Pick<FieldError, 'message' | 'types'>

// Make sure we can distinguish between a single error and a map of errors
function isFieldError(error: FieldErrors | FieldError): error is FieldError {
  return 'ref' in error
}

/**
 * Returns an array of error messages extracted from anything that looks like a
 * FieldErrorLike.
 */
function getFieldErrorMessages(error: FieldErrorLike) {
  const errorMessages = new Array<string>()

  if (error?.message) {
    errorMessages.push(error.message)
  }

  // If there are multiple types of errors for a field, iterate over them
  if (error?.types) {
    for (const validateResult of Object.values(error.types)) {
      if (Array.isArray(validateResult)) {
        errorMessages.push(...validateResult)
      }

      if (typeof validateResult === 'string') {
        errorMessages.push(validateResult)
      }

      // Ignore boolean and undefined results, we might want to add a message
      // based on the type key instead of the result but this is not needed at
      // the moment
    }
  }

  return errorMessages
}

/**
 * Recursively collects all errors messages from a React hook form starting at a
 * given path.
 */
function getErrorMessagesFromPath(errors: FieldErrors | FieldError, path: string) {
  const errorMessages = new Array<string>()

  // Traverse the object following the path
  const pathError = get(errors, path)
  if (!pathError) {
    return []
  }

  // Check if there is an exact match at the path
  if (isFieldError(pathError)) {
    errorMessages.push(...getFieldErrorMessages(pathError))
  }

  // Recursively collect error messages starting from the given path
  const collectMessages = (errorObj: FieldErrors | FieldError) => {
    for (const key in errorObj) {
      const nestedError = get(errorObj, key)
      errorMessages.push(...getFieldErrorMessages(nestedError))

      if (typeof nestedError === 'object' && !Array.isArray(nestedError)) {
        collectMessages(nestedError) // Recursively go deeper if it's an object
      }
    }
  }

  collectMessages(pathError)
  return errorMessages
}

export interface ErrorContainerProps {
  children: (onClick: () => void, hasErrors: boolean) => ReactNode
  // A string or array of paths that will be checked for errors
  path: string | string[]
}

export function ErrorContainer({ children, path }: ErrorContainerProps) {
  const reduxErrors = useSelector(useErrorSelector(path))
  const form = useFormContext()

  const errors = useMemo(() => {
    if (!form) {
      return reduxErrors
    }

    const fieldPaths = Array.isArray(path) ? path : [path]

    // Find and report errors for the given paths, including nested errors
    const messages = fieldPaths.flatMap((fieldPath) =>
      getErrorMessagesFromPath(form.formState.errors, fieldPath)
    )

    const fieldErrors = messages.map((message) => ({
      path: fieldPaths.join('.'),
      type: 'error',
      message,
    }))

    return [...reduxErrors, ...fieldErrors]
  }, [form, path, reduxErrors])

  if (errors.length === 0) {
    return null
  }

  const hasErrors = errors.some((error) => error.type === 'error')

  return children(
    toastExplain(
      <NotificationModal
        body={errors.map((error) => (
          <Text
            key={`${error.path}${error.message}`}
            colorName={error.type === 'warning' ? COLORNAME_SUPPORT_3 : COLORNAME_SUPPORT_4}
            size={SIZE_CAPTION_2}>
            {`- ${error.message}`}
          </Text>
        ))}
        modalType={{
          label: `Validation ${hasErrors ? 'error' : 'warning'}`,
          icon: (
            <IconInCircle
              colorStyle={hasErrors ? 'red' : 'yellow'}
              icon={ICON_EXCLAMATION_MARK}
              size={'small'}
            />
          ),
        }}
      />
    ),
    hasErrors
  )
}
