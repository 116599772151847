import { formatDate } from '@gain/utils/date'
import { useTheme } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { differenceInDays } from 'date-fns/differenceInDays'
import { formatDistance } from 'date-fns/formatDistance'
import { parseJSON } from 'date-fns/parseJSON'
import React, { useMemo } from 'react'

export interface AssetUnpublishedAtCellProps {
  unpublishedAt: string | null
}

export default function AssetUnpublishedAtCell({ unpublishedAt }: AssetUnpublishedAtCellProps) {
  const theme = useTheme()

  const color = useMemo(() => {
    if (!unpublishedAt) {
      return undefined
    }

    const diff = differenceInDays(new Date(), parseJSON(unpublishedAt))
    if (diff >= 7) {
      return theme.palette.error.main
    } else if (diff >= 3) {
      return theme.palette.warning.main
    }

    return undefined
  }, [theme, unpublishedAt])

  if (!unpublishedAt) {
    return <>-</>
  }

  return (
    <Tooltip
      title={formatDate(unpublishedAt, { format: 'dateTime' })}
      disableInteractive>
      <span style={{ color }}>
        {formatDistance(parseJSON(unpublishedAt), new Date(), {
          addSuffix: true,
        })}
      </span>
    </Tooltip>
  )
}
