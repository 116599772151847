import { AnnualReport, AnnualReportFile } from '@gain/rpc/cms-model'
import React from 'react'
import { useField } from 'react-final-form'

import FlexItem from '../../../../Components/Tabular/FlexTable/FlexItem'
import FileActionsGroup, {
  FILE_ACTION_REPLACE,
} from '../../../../Compositions/File/file-actions-group'
import InputFile from '../../../../Compositions/File/InputFile'
import { FlexContainerRepeaterComposition } from '../../../../Compositions/Repeater/flex-container-repeater-composition'
import { getAnnualReportFilename } from '../../../../util/annual-report'

interface LegalEntityAnnualReportFormFileLineProps {
  name: string
  arName: string
}

export default function LegalEntityAnnualReportFormFileLine(
  props: LegalEntityAnnualReportFormFileLineProps
) {
  const { arName } = props
  const {
    input: { value: report },
  } = useField<AnnualReport>(arName)
  const { name } = props
  const {
    input: { value: arFile },
  } = useField<AnnualReportFile>(name)

  return (
    <FlexContainerRepeaterComposition
      name={name}
      style={{ alignItems: 'center' }}
      warningText={
        arFile.internal
          ? [
              'This file is used for parsing financials.',
              'By deleting this file, the financials will also be lost. Are you sure?',
            ].join(' ')
          : undefined
      }>
      <FlexItem style={{ flex: '1 1 auto' }}>
        <div style={{ display: 'flex', maxWidth: '100%' }}>
          {!arFile.internal ? (
            // Public files are allowed to be edited by an analyst
            <InputFile
              getExtras={(fileId) => ({ fileId })}
              path={name}
              secure>
              <FileActionsGroup
                action={FILE_ACTION_REPLACE}
                fileId={arFile.fileId}
                filename={getAnnualReportFilename(arFile, report)}
              />
            </InputFile>
          ) : (
            // Private files are just for display
            <FileActionsGroup
              fileId={arFile.fileId}
              filename={getAnnualReportFilename(arFile, report)}
            />
          )}
        </div>
      </FlexItem>
    </FlexContainerRepeaterComposition>
  )
}
