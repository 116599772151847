import Stack from '@mui/material/Stack'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import React, { ChangeEvent, useCallback } from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'

import InputFieldHelperText from './input-field-helper-text'
import InputFieldTextCharacterCount from './input-field-text-character-count'
import { defaultTransform, InputFieldTransform } from './input-field-transform'
import { useFieldName, useInputFormContext } from './input-form-hooks'
import InputLabelConsistencyGuideInfo from './input-label-consistency-guide-info'

export interface InputFieldTextProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Pick<
    TextFieldProps,
    | 'disabled'
    | 'helperText'
    | 'InputLabelProps'
    | 'InputProps'
    | 'minRows'
    | 'multiline'
    | 'onChange'
    | 'placeholder'
    | 'required'
    | 'size'
    | 'sx'
    | 'type'
  > {
  name: TName
  label?: string
  defaultValue?: FieldPathValue<TFieldValues, TName>
  consistencyGuideName?: string
  transform?: InputFieldTransform
  recommendedLength?: number
}

export default function InputFieldText<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  required,
  defaultValue,
  onChange,
  consistencyGuideName,
  transform = defaultTransform,
  disabled,
  recommendedLength,
  ...textFieldProps
}: InputFieldTextProps<TFieldValues, TName>) {
  const inputForm = useInputFormContext()
  const fieldName = useFieldName<TName>(name)

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: { required },
    defaultValue,
  })

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const transformedEvent = transform.output(event)

      field.onChange(transformedEvent)
      onChange?.(transformedEvent)
    },
    [field, onChange, transform]
  )

  return (
    <TextField
      {...textFieldProps}
      disabled={inputForm.disabled || disabled}
      error={Boolean(fieldState.error)}
      helperText={
        <Stack justifyContent={'space-between'}>
          <InputFieldHelperText message={fieldState.error?.message ?? textFieldProps.helperText} />
          <InputFieldTextCharacterCount
            recommendedLength={recommendedLength}
            text={field.value?.toString()}
          />
        </Stack>
      }
      inputRef={field.ref}
      label={
        label && (
          <>
            {label}

            <InputLabelConsistencyGuideInfo
              label={label || ''}
              name={consistencyGuideName || name}
            />
          </>
        )
      }
      name={field.name}
      onBlur={inputForm.onBlur(fieldName, field.onBlur)}
      onChange={handleChange}
      required={required}
      value={transform.input(field.value)}
      variant={'outlined'}
      fullWidth
    />
  )
}
