import type { ApiKey } from '@gain/rpc/cms-model'
import { listFilter } from '@gain/rpc/utils'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import React, { useCallback, useRef } from 'react'
import { Route } from 'react-router'

import SwrDataGrid, { SwrDataGridRef } from '../../common/swr-data-grid'
import { ItemPageContent } from '../../layout/item-page'
import { formatDistanceFromNow } from '../../util/dateFormat'
import RouteApiKey from './route-api-key'
import { CUSTOMER_API_KEYS_PATH } from './route-api-keys-path'

interface RouteApiKeysProps {
  customerId: number
}

export default function RouteApiKeys({ customerId }: RouteApiKeysProps) {
  const dataGridRef = useRef<SwrDataGridRef>(null)

  const handleRefreshGrid = useCallback(() => {
    dataGridRef.current?.refresh()
  }, [])

  return (
    <ItemPageContent variant={'grid'}>
      <Card>
        <SwrDataGrid<'customer.listApiKeys', ApiKey>
          ref={dataGridRef}
          actions={[
            <Button
              key={'new-api-key'}
              href={'./api-keys/new'}
              variant={'contained'}>
              New
            </Button>,
          ]}
          columns={[
            {
              field: 'createdAt',
              headerName: 'Created at',
              width: 160,
              valueGetter: (value) => formatDistanceFromNow(value, 'Never'),
            },
            {
              field: 'keyPrefix',
              headerName: 'Key prefix',
              width: 200,
              sortable: false,
              valueGetter: (value) => `${value}_**********`,
            },
            {
              field: 'note',
              headerName: 'Note',
              sortable: false,
              flex: 1,
              valueGetter: (value) => value,
            },
            {
              field: 'expiresAt',
              headerName: 'Expires at',
              width: 160,
              valueGetter: (value) => formatDistanceFromNow(value, 'Never'),
            },
          ]}
          filter={[listFilter('customerId', '=', customerId)]}
          label={'API Keys'}
          method={'customer.listApiKeys'}
          path={':id'}
          sort={[{ field: 'createdAt', direction: 'desc' }]}
          disableColumnFilter
          disableSearch
          useFullHeight
        />
      </Card>

      <Route path={`${CUSTOMER_API_KEYS_PATH}/:apiKeyId`}>
        <RouteApiKey
          customerId={customerId}
          onCrudAction={handleRefreshGrid}
        />
      </Route>
    </ItemPageContent>
  )
}
