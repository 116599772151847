import { createUseAppListItemSwr, createUseCmsListItemSwr, createUseCmsSwr } from '@gain/api/swr'

export const useTagListItem = createUseAppListItemSwr('data.listTags', 'id')
export const usePersonListItem = createUseCmsListItemSwr('data.listPersons', 'id')
export const useProjectListItem = createUseCmsListItemSwr('cms.listProjects', 'id')
export const useAssetListItem = createUseCmsListItemSwr('data.listAssets', 'id')
export const useInvestorListItem = createUseCmsListItemSwr('data.listInvestors', 'id')

export const useFileInfo = createUseCmsSwr('data.getFileInfo')
export const useCustomer = createUseCmsSwr('customer.getCustomer')
export const useApiKey = createUseCmsSwr('customer.getApiKey')
export const useNotes = createUseCmsSwr('cms.listNotes')
export const useInvestorStrategies = createUseCmsSwr('data.listInvestorStrategies')
export const useCurrencies = createUseCmsSwr('data.listCurrencies')
export const useConference = createUseCmsSwr('data.getConference')
export const useConferenceVenue = createUseCmsSwr('data.getConferenceVenue')
export const useAdvisor = createUseCmsSwr('data.getAdvisor')
