import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch, useParams } from 'react-router'

import LayoutItemPage from '../layout/item-page'
import { fetchItemActions } from '../redux/reducer/fetchItem'
import {
  ARTICLE_INTHENEWS_TAB_PATH,
  ARTICLE_RESEARCH_TAB_PATH,
  CUSTOMER_DETAIL_TAB_PATH,
  INDUSTRY_DETAIL_TAB_PATH,
  LEGAL_ENTITY_DETAIL_TAB_PATH,
  USER_DETAIL_TAB_PATH,
} from '../util/path'
import ArticleInthenewsDetailRoutes from './DetailRoutes/ArticleInthenewsDetailRoutes'
import ArticleResearchDetailRoutes from './DetailRoutes/ArticleResearchDetailRoutes'
import CustomerDetailRoutes from './DetailRoutes/customer-detail-routes'
import IndustryDetailRoutes from './DetailRoutes/IndustryDetailRoutes'
import LegalEntityDetailRoutes from './DetailRoutes/LegalEntityDetailRoutes'
import UserDetailRoutes from './DetailRoutes/user-detail-routes'

export default function ItemRoutes() {
  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    dispatch(fetchItemActions.fetchItem(params))
  }, [dispatch, params])

  return (
    <LayoutItemPage>
      <Switch>
        <Route
          component={ArticleInthenewsDetailRoutes}
          path={ARTICLE_INTHENEWS_TAB_PATH}
        />
        <Route
          component={ArticleResearchDetailRoutes}
          path={ARTICLE_RESEARCH_TAB_PATH}
        />
        <Route
          component={IndustryDetailRoutes}
          path={INDUSTRY_DETAIL_TAB_PATH}
        />
        <Route
          component={UserDetailRoutes}
          path={USER_DETAIL_TAB_PATH}
        />
        <Route
          component={CustomerDetailRoutes}
          path={CUSTOMER_DETAIL_TAB_PATH}
        />
        <Route
          component={LegalEntityDetailRoutes}
          path={LEGAL_ENTITY_DETAIL_TAB_PATH}
        />
      </Switch>
    </LayoutItemPage>
  )
}
