import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import FileActionsGroup, {
  FILE_ACTION_DISPLAY,
  FILE_ACTION_REPLACE,
  FILE_ACTION_TYPE_IMAGE,
} from '../file-actions-group'
import { IMAGE_FIlES } from '../../../util/fileTypes'
import MethodContext from '../../../Context/method.context'
import { File } from '../File'
import { nextResponse } from '../../../redux/action/nextAction'
import { updateItem } from '../../../redux/action/updateItem'
import { partial } from '../../../util/partial'
import InputError from '../../../Components/FormElements/Error/InputError'
import { ErrorContainer } from '../../UpdateForm/ErrorContainer'
import { nextValidateItem } from '../../../redux/action/validateItem'

const LogoActionsGroup = ({ path, action }) => (
  <Field name={path}>
    {({ input }) => (
      <FileActionsGroup
        fileId={input.value.logoFileId}
        imageUrl={input.value.logoFileUrl}
        filename={input.value.logoFilename}
        action={action}
        fileType={FILE_ACTION_TYPE_IMAGE}
      />
    )}
  </Field>
)

LogoActionsGroup.propTypes = {
  path: PropTypes.string,
  action: PropTypes.string.isRequired,
}

LogoActionsGroup.defaultProps = {
  path: undefined,
}

const LogoField = ({ path }) => (
  <>
    <MethodContext.Consumer>
      {({ id, methods, disabled }) =>
        disabled ? (
          <LogoActionsGroup
            path={path}
            action={FILE_ACTION_DISPLAY}
          />
        ) : (
          <File
            next={nextResponse((result) =>
              updateItem(
                methods.update,
                {
                  id,
                  ...partial(path ? `${path}.logoFileId` : 'logoFileId', result),
                },
                methods.validate && nextValidateItem(methods.validate, { id })
              )
            )}
            accept={IMAGE_FIlES}>
            <LogoActionsGroup
              path={path}
              action={FILE_ACTION_REPLACE}
            />
          </File>
        )
      }
    </MethodContext.Consumer>
    <ErrorContainer path={path ? `${path}.logoFileId` : 'logoFileId'}>
      {(onClick, hasErrors) => (
        <InputError
          onClick={onClick}
          hasErrors={hasErrors}
        />
      )}
    </ErrorContainer>
  </>
)

LogoField.propTypes = {
  path: PropTypes.string,
}

LogoField.defaultProps = {
  path: undefined,
}

export default LogoField
