import React from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'

import InputFieldText, { InputFieldTextProps } from './input-field-text'

export type InputFieldPercentageProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = InputFieldTextProps<TFieldValues, TName>

export default function InputFieldPercentage<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: InputFieldPercentageProps<TFieldValues, TName>) {
  return (
    <InputFieldText
      transform={{
        input: (value) => {
          // Always do toFixed with the parsed float, prevents floating-point arithmetic issues
          return value ? parseFloat((parseFloat(`${value}`) * 100).toFixed(2)) : ''
        },
        output: (event) => {
          event.target.value = `${parseFloat(event.target.value) / 100}`

          return event
        },
      }}
      type={'number'}
      {...props}
    />
  )
}
