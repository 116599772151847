import { baseUrl } from '@gain/rpc/utils'
import React, { PropsWithChildren } from 'react'
import { generatePath } from 'react-router'

import { ICON_PREVIEW } from '../../Components/Icons/Icon'
import IconInCircle from '../../Components/Icons/icon-in-circle'

interface PreviewLinkProps {
  to: string
  params: object
}

export default function PreviewLink({ to, params, children }: PropsWithChildren<PreviewLinkProps>) {
  return (
    <a
      href={`${baseUrl}${generatePath(to, params)}?preview=1`}
      rel={'noopener noreferrer'}
      style={{ textDecoration: 'none' }}
      target={'_blank'}>
      {children || (
        <IconInCircle
          colorStyle={'lighten'}
          icon={ICON_PREVIEW}
        />
      )}
    </a>
  )
}
