import { useRpcClient } from '@gain/api/swr'
import { RpcMethodMap } from '@gain/rpc/cms-model'
import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router'

import { ROUTE_PERSON_PROFILE_PATH } from './index'

export function useCreateNewPersonCallback() {
  const rpcClient = useRpcClient<RpcMethodMap>()
  const history = useHistory()

  return useCallback(async () => {
    const response = await rpcClient({
      method: 'data.createPerson',
      params: { partial: {} },
    })

    if (response && response?.id) {
      history.push(generatePath(ROUTE_PERSON_PROFILE_PATH, { id: response.id }))
    }
  }, [history, rpcClient])
}
