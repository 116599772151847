import Button from '@mui/material/Button'
import React, { useCallback, useRef } from 'react'

import { SwrDataGridRef } from '../../common/swr-data-grid'
import UserCreateModal from '../../Compositions/Modals/CreateModals/user-create-modal.component'
import LayoutDataGrid from '../../layout/data-grid'
import UsersList from '../../Pages/Lists/helpers/users-list'
import { toastCreate } from '../../util/toastCreate'

export default function RouteUsers() {
  const dataGridRef = useRef<SwrDataGridRef>(null)

  const handleRefreshGrid = useCallback(() => {
    dataGridRef.current?.refresh()
  }, [dataGridRef])

  return (
    <LayoutDataGrid title={'Users'}>
      <UsersList
        actions={[
          <Button
            key={'new-user'}
            onClick={toastCreate(<UserCreateModal afterAction={handleRefreshGrid} />)}
            variant={'contained'}>
            New
          </Button>,
        ]}
        dataGridRef={dataGridRef}
        useFullHeight
      />
    </LayoutDataGrid>
  )
}
