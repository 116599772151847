import { ConferenceVenueListItem } from '@gain/rpc/cms-model'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import React, { useCallback, useRef } from 'react'
import { Route } from 'react-router'

import SwrDataGrid, { SwrDataGridRef } from '../../common/swr-data-grid'
import LayoutDataGrid from '../../layout/data-grid'
import RouteVenue from './route-venue'
import { NAV_VENUES } from './route-venues-path'

export default function RouteVenues() {
  const dataGridRef = useRef<SwrDataGridRef>(null)

  const handleRefreshGrid = useCallback(() => {
    dataGridRef.current?.refresh()
  }, [])

  return (
    <LayoutDataGrid title={NAV_VENUES}>
      <Card>
        <SwrDataGrid<'data.listConferenceVenues', ConferenceVenueListItem>
          ref={dataGridRef}
          actions={[
            <Button
              key={'new-venue'}
              href={'/venues/new'}
              variant={'contained'}>
              New
            </Button>,
          ]}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              width: 216,
              filterable: false,
            },
            {
              field: 'address',
              headerName: 'Address',
              flex: 1,
              filterable: false,
            },
            {
              field: 'country',
              headerName: 'Country',
              minWidth: 150,
              type: 'listMethod',
              method: 'data.listRegions',
              labelProp: 'title',
              valueProp: 'name',
            },
            {
              field: 'updatedAt',
              headerName: 'Updated at',
              type: 'dateTime',
              minWidth: 150,
            },
            {
              field: 'createdAt',
              headerName: 'Created at',
              type: 'dateTime',
              minWidth: 150,
            },
          ]}
          columnVisibilityModel={{
            // The country is also visible inside the address, we only want it for the filters
            country: false,
          }}
          label={NAV_VENUES}
          method={'data.listConferenceVenues'}
          path={':id'}
          sort={[{ field: 'name', direction: 'asc' }]}
          useFullHeight
        />
      </Card>

      <Route path={'/venues/:venueId'}>
        <RouteVenue onCrudAction={handleRefreshGrid} />
      </Route>
    </LayoutDataGrid>
  )
}
