export enum InvestorRouteTabs {
  Profile = 'profile',
  Strategies = 'strategies',
  Funds = 'funds',
  LinkedCompanies = 'linked-companies',
}

const INVESTOR_PAGE_PATH = '/investors/:id'
export const ROUTE_INVESTOR_TABS_PATH = `${INVESTOR_PAGE_PATH}/:tab?`
export const INVESTOR_PROFILE_PATH = `${INVESTOR_PAGE_PATH}/${InvestorRouteTabs.Profile}`
export const INVESTOR_STRATEGIES_PATH = `${INVESTOR_PAGE_PATH}/${InvestorRouteTabs.Strategies}`
export const INVESTOR_FUNDS_PATH = `${INVESTOR_PAGE_PATH}/${InvestorRouteTabs.Funds}`
export const INVESTOR_LINKED_COMPANIES_PATH = `${INVESTOR_PAGE_PATH}/${InvestorRouteTabs.LinkedCompanies}`

export const INVESTOR_PREVIEW_PATH = '/app/owner/:id/:name'
