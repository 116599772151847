import React from 'react'
import { match, Route, Switch } from 'react-router'

import { MethodContextProvider } from '../../Context/method-context.provider'
import CustomerDetailSecurityPage from '../../Pages/Item/CustomerDetail/customer-detail-security-page'
import CustomerDetailUsersPage from '../../Pages/Item/CustomerDetail/customer-detail-users-page'
import CustomerDetailGeneralPage from '../../Pages/Item/CustomerDetail/CustomerDetailGeneralPage'
import CustomerDetailSubscriptionPage from '../../Pages/Item/CustomerDetail/CustomerDetailSubscriptionPage'
import CustomerDetailHeader from '../../Pages/Item/CustomerDetail/helpers/CustomerDetailHeader'
import { CUSTOMER_METHODS } from '../../util/methods'
import {
  CUSTOMER_GENERAL_PATH,
  CUSTOMER_SECURITY_PATH,
  CUSTOMER_SUBSCRIPTION_PATH,
  CUSTOMER_USERS_PATH,
} from '../../util/path'
import RouteApiKeys from '../route-api-keys/route-api-keys'
import { CUSTOMER_API_KEYS_PATH } from '../route-api-keys/route-api-keys-path'
import ItemContainer from './helpers/ItemContainer'

interface CustomerDetailRoutesProps {
  match: match<{ id: string }>
}

export default function CustomerDetailRoutes({ match: { params } }: CustomerDetailRoutesProps) {
  return (
    <ItemContainer>
      {(data) => (
        <MethodContextProvider
          data={data}
          methods={CUSTOMER_METHODS}>
          <CustomerDetailHeader
            data={data}
            params={params}
          />
          <Switch>
            <Route
              component={CustomerDetailSubscriptionPage}
              path={CUSTOMER_SUBSCRIPTION_PATH}
              exact
            />
            <Route
              component={CustomerDetailUsersPage}
              path={CUSTOMER_USERS_PATH}
              exact
            />
            <Route
              component={CustomerDetailSecurityPage}
              path={CUSTOMER_SECURITY_PATH}
              exact
            />
            <Route path={CUSTOMER_API_KEYS_PATH}>
              <RouteApiKeys customerId={parseInt(params.id)} />
            </Route>
            <Route
              component={CustomerDetailGeneralPage}
              path={CUSTOMER_GENERAL_PATH}
              exact
            />
          </Switch>
        </MethodContextProvider>
      )}
    </ItemContainer>
  )
}
