import Head from '@gain/modules/head'
import React from 'react'
import PropTypes from 'prop-types'
import {
  LEGAL_ENTITY_ASSETS_PATH,
  LEGAL_ENTITY_PREVIEW_PATH,
  LEGAL_ENTITY_PROFILE_PATH,
  LEGAL_ENTITY_REPORTS_PATH,
} from '../../../../util/path'
import PageHeader from '../../../../Components/Layout/PageHeader'
import PageTitle from '../../../../Components/PageTitle/CenteredPageHeader'
import PageTitleCenter from '../../../../Components/PageTitle/CenteredPageHeaderTitle'
import Text, { SIZE_CAPTION_2, SIZE_HEADER_3 } from '../../../../Components/Text/Text'
import PageNavigationComposition from '../../../../Compositions/PageNavigation/PageNavigationComposition'
import { LIST_ASSETS_METHOD } from '../../../../util/methods'
import PageHeaderButtons from '../../../../Components/PageTitle/PageHeaderButtons'
import { COLORNAME_WHITE70 } from '../../../../util/colors'
import ActionsMenu from '../../../../Compositions/ActionsMenu/ActionsMenu'
import LegalEntityActions from '../../../../Compositions/Actions/LegalEntityActions'
import PreviewLink from '../../../../common/preview-link'

const LINKS = [
  { title: 'Legal entity', path: LEGAL_ENTITY_PROFILE_PATH, tab: 'profile' },
  {
    title: 'Annual reports',
    path: LEGAL_ENTITY_REPORTS_PATH,
    tab: 'reports',
  },
  { title: 'Linked companies', path: LEGAL_ENTITY_ASSETS_PATH, tab: 'assets' },
]

const LegalEntityDetailHeader = ({ data, params }) => {
  const links = LINKS.map((link) => {
    if (link.tab === 'assets') {
      return {
        ...link,
        params: {
          ...params,
          method: LIST_ASSETS_METHOD,
          sort: 'name',
          filter: `id=${data.assets.map((asset) => asset.assetId).join('|')}`,
        },
      }
    }

    return {
      ...link,
      params,
    }
  })

  return (
    <PageHeader>
      <PageTitle>
        <div />
        <PageTitleCenter>
          <Head>
            <title>{data.name}</title>
          </Head>
          <Text size={SIZE_HEADER_3}>{data.name}</Text>
          <Text
            size={SIZE_CAPTION_2}
            colorName={COLORNAME_WHITE70}>
            Legal entity
          </Text>
        </PageTitleCenter>
        <PageHeaderButtons>
          <PreviewLink
            to={LEGAL_ENTITY_PREVIEW_PATH}
            params={{
              id: data.id,
              name: data.name || '-',
            }}
          />
          <ActionsMenu content={<LegalEntityActions data={data} />} />
        </PageHeaderButtons>
      </PageTitle>
      <PageNavigationComposition links={links} />
    </PageHeader>
  )
}

LegalEntityDetailHeader.propTypes = {
  data: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
}

export default LegalEntityDetailHeader
