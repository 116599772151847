import React, { ForwardedRef, forwardRef, useImperativeHandle } from 'react'

import { InputFieldText, InputFormProvider, useInputFormAPI } from '../../../common/input-fields'
import { yupLinkedInCompanyUrl } from '../../../common/input-fields/input-field-yup'

interface AutomatedAssetFormProps {
  disabled: boolean
  onCreate?: (assetId: number) => void
}

// Expose submit handler via forwarded ref to allow the containing component
// to handle form submission
export interface AutomatedAssetFormRef {
  submit: () => Promise<void>
}

/**
 * Allows the user to create an automated asset profile based on a LinkedIn
 * company URL. Form submission is delegated to containing component via ref.
 */
function AutomatedAssetForm(
  { disabled, onCreate }: AutomatedAssetFormProps,
  ref: ForwardedRef<AutomatedAssetFormRef>
) {
  const inputFormAPI = useInputFormAPI({
    validationSchema: {
      linkedinUrl: yupLinkedInCompanyUrl().required(),
    },
    createMethod: 'data.createAutomatedAsset',
  })

  const handleSubmit = inputFormAPI.form.handleSubmit(async (data) => {
    await inputFormAPI.create(
      {
        linkedinUrl: data.linkedinUrl,
      },
      (assetId) => {
        onCreate?.(assetId)
      }
    )
  })

  // Forward submit handler to the ref
  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
  }))

  return (
    <InputFormProvider form={inputFormAPI.form}>
      <InputFieldText
        disabled={disabled}
        label={'LinkedIn URL'}
        name={'linkedinUrl'}
        placeholder={'https://www.linkedin.com/company/walgreen'}
        required
      />
    </InputFormProvider>
  )
}

export default forwardRef(AutomatedAssetForm)
