import { useDialogState } from '@gain/utils/dialog'
import Button from '@mui/material/Button'
import React from 'react'

import CreateAssetDialog from './create-asset-dialog'

interface CreateAssetButtonProps {
  onCreate?: (assetId: number) => void
}

export default function CreateAssetButton({ onCreate }: CreateAssetButtonProps) {
  const [showDialog, handleShow, handleClose] = useDialogState()

  return (
    <>
      <Button
        onClick={handleShow}
        variant={'contained'}>
        Create new company
      </Button>
      <CreateAssetDialog
        onClose={handleClose}
        onCreate={onCreate}
        show={showDialog}
      />
    </>
  )
}
